<template>
  <Cond :where="where" @refresh="refresh">
    <div class="mgb10">
      <el-tag
        type="primary"
        effect="dark"
        size="small"
        style="margin-right: 10px"
        >总数: {{ tag.all }}</el-tag
      >
      <el-tag
        type="warning"
        effect="dark"
        size="small"
        style="margin-right: 10px"
        >今日新增: {{ tag.today }}</el-tag
      >
      <el-tag type="success" effect="dark" size="small"
        >昨日新增: {{ tag.yesterday }}</el-tag
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      size="small"
      v-loading="where.loading"
    >
      <el-table-column prop="shop_name" label="账号信息" width="180">
        <template slot-scope="scope">
          <div>店铺:</div>
          <div class="mgb10">{{ scope.row.shop_name }}</div>
          <div>账号:</div>
          <div class="mgb10">{{ scope.row.user_name }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="master_id" label="账号ID" width="120">
      </el-table-column>
      <el-table-column prop="shop_id" label="shopID" width="120">
      </el-table-column>
      <el-table-column prop="sys_shop_id" label="sysShopID" width="100">
      </el-table-column>
      <el-table-column prop="source" label="平台" width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.source == 1">
            <el-tag type="primary" effect="dark" size="small">SHOPEE</el-tag>
          </div>
          <div v-else-if="scope.row.source == 2">
            <el-tag type="success" effect="dark" size="small">LAZADA</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="db_code" label="数据库编码" width="100">
      </el-table-column>
      <el-table-column prop="source" label="套餐" width="300">
        <template slot-scope="scope">
          <div v-if="scope.row.modules">
            <div v-for="(m, mi) in scope.row.modules" :key="mi">
              <div class="f-info">{{ m.module_desc }}</div>
              <div>
                到期时间:
                <span
                  :class="[
                    disNow(m.expiration_date) > 7 ? 'f-primary' : 'f-error',
                  ]"
                  >{{ $func.format(m.expiration_date) }} ({{
                    disNow(m.expiration_date)
                  }}天后过期)</span
                >
              </div>
            </div>
          </div>
          <div v-else>
            <el-tag type="info" size="small">未购买套餐</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="ID" width="80"> </el-table-column>
      <el-table-column prop="table_code" label="表编码" width="80">
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="100">
      </el-table-column>
      <el-table-column prop="id" label="授权时间" width="160">
        <template slot-scope="scope">
          {{ $func.format(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <div>
            <el-button @click="buy(scope.row)" type="text" size="small"
              >赠送</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mgt10 text-center">
      <el-pagination
        @current-change="loadList"
        :current-page.sync="where.page"
        background
        layout="prev, pager, next"
        :page-size="where.pageSize"
        :total="where.total"
      >
      </el-pagination>
    </div>
    <Buy ref="buy" @refresh="loadList"></Buy>
  </Cond>
</template>
<script>
import Cond from "./shopscond.vue";
import Buy from "@/components/backend/member/buy.vue";
export default {
  components: {
    Cond,
    Buy,
  },
  data() {
    return {
      url: {
        shoplist: "/sysadminmember/shoplist",
      },
      tag: {
        today: 0,
        yesterday: 0,
        all: 0,
      },
      where: {
        shop_name: "",
        user_name: "",
        shop_id:0,
        page: 1,
        pageSize: 20,
        total: 0,
        loading: false,
      },
      tableData: [],
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    buy: function (row) {
      this.$refs.buy.show(row);
    },
    disNow: function (t) {
      var disTotalDate = t - parseInt(new Date().getTime() / 1000);
      if (disTotalDate < 0) {
        return 0;
      }
      return Math.ceil(disTotalDate / (24 * 3600));
    },
    refresh: function () {
      this.where.page = 1;
      this.loadList();
    },
    loadList: function () {
      this.where.loading = true;
      this.$rq.postAction(this.url.shoplist, this.where).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.tableData = [];
          this.tag.today = rs.today_increase;
          this.tag.yesterday = rs.yesterday_increase;
          this.tag.all = rs.all;
          for (var i in rs.list) {
            this.tableData.push(rs.list[i]);
          }
          this.where.total = parseInt(rs.pageInfo.total);
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
  },
};
</script>