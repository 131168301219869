<template>
  <Main>
    <el-card class="box-card">
      <el-form :inline="true" class="demo-form-inline" size="mini">
        <el-form-item label="店铺名称">
          <el-input
            clearable
            placeholder="请输入店铺名称"
            v-model="where.shop_name"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="用户账号">
          <el-input
            clearable
            placeholder="请输入用户账号"
            v-model="where.user_name"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="店铺ID">
          <el-input
            clearable
            placeholder="请输入店铺ID"
            v-model="where.shop_id"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="$emit('refresh')"
            type="primary"
            icon="el-icon-search"
            circle
          ></el-button>
        </el-form-item>
      </el-form>
      <div>
        <slot></slot>
      </div>
    </el-card>
  </Main>
</template>
<script>
import Main from "@/components/backend/common/main.vue";
export default {
  components: {
    Main,
  },
  props: {
    where: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>