<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="visiable"
    :before-close="handleClose"
  >
    <el-form ref="form" :model="form" size="small" label-position="top">
      <el-form-item label="套餐名称" v-if="cond.goods">
        <el-input
          placeholder="请输入套餐名称"
          v-model="cond.goods.goods_name"
          :disabled="true"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="天数或指定过期时间">
        <el-radio-group v-model="form.op">
          <el-radio-button label="gif">赠送天数</el-radio-button>
          <el-radio-button label="edit">到期时间</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="赠送天数" v-if="form.op == 'gif'">
        <el-input-number
          v-model="form.give_day"
          label="请输入赠送天数"
          :min="0"
        ></el-input-number>
        <span class="pdl10">天</span>
      </el-form-item>
      <el-form-item label="到期时间" v-if="form.op == 'edit'">
        <el-date-picker
          @change="changeDate"
          v-model="form.expiration_date_t"
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="套餐金额">
        <el-input-number
          v-model="form.buy_amount"
          label="请输入套餐金额"
          :min="0"
        ></el-input-number>
        <span class="pdl10">元</span>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入备注"
          v-model="form.remark"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="模块" class="myview">
        <el-checkbox-group v-model="form.moduleIds">
          <el-checkbox
            border
            v-for="(m, mi) in cond.moduleList"
            :label="m.id"
            :key="mi"
            >{{ m.module_name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="form.loading" @click="submit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      title: "赠送套餐",
      visiable: false,
      form: this.getDefaultData(),
      cond: {
        moduleList: [],
        goods: undefined,
      },
      url: {
        orderfree: "/sysadminmember/orderfree",
        modulelist: "/sysadminsystem/modulelist",
        freegoodsget: "/sysadminmember/freegoodsget",
      },
    };
  },
  created() {},
  methods: {
    getDefaultData: function () {
      return {
        sys_goods_id: 0,
        channel_id: 0,
        give_day: 0,
        buy_amount: 0,
        expiration_date: 0,
        expiration_date_t: "",
        remark: "",
        moduleIds: [],
        op: "gif",
        loading: false,
        source: 0,
      };
    },
    handleClose: function () {
      this.$refs.form.resetFields();
      this.visiable = false;
    },
    show: async function (shop) {
      this.form.channel_id = shop.id;
      this.form.source = shop.source;
      //this.form.expiration_date_t = "";
      var r = await this.freeGoodsGet();
      var r2 = await this.modulelist();
      if (r.code == 1) {
        this.form.sys_goods_id = r.info.id;
        this.cond.goods = r.info;
        if (r.info.goods_items != "") {
          r.info.goods_items = JSON.parse(r.info.goods_items);
          this.form.moduleIds = [];
          for (var j in r.info.goods_items) {
            var item = r.info.goods_items[j];
            this.form.moduleIds.push(parseInt(item.relation_module_id));
          }
        }
      }
      if (r2.code == 1) {
        this.cond.moduleList = [];
        for (var i in r2.list) {
          this.cond.moduleList.push(r2.list[i]);
        }
      }
      this.visiable = true;
    },
    modulelist: function () {
      return this.$rq.postAction(this.url.modulelist, {
        source: this.form.source,
        page: 1,
        pageSize: 1000,
      });
    },
    freeGoodsGet: function () {
      return this.$rq.postAction(this.url.freegoodsget, {
        source: this.form.source,
      });
    },
    submit: function () {
      this.$refs.form.validate((v) => {
        if (v) {
          this.form.loading = true;
          this.$rq.postAction(this.url.orderfree, this.form).then((rs) => {
            this.form.loading = false;
            if (rs.code == 1) {
              this.visiable = false;
              this.$message({
                type: "success",
                message: rs.msg,
              });
              this.$emit("refresh");
            } else {
              this.$message({
                type: "warning",
                message: rs.err,
              });
            }
          });
        }
      });
    },
    changeDate: function () {
      if (this.form.expiration_date_t) {
        this.form.expiration_date = parseInt(
          this.form.expiration_date_t.getTime() / 1000
        );
      }
      //this.$func.log(this.form.expiration_date);
    },
  },
};
</script>
<style scoped>
.myview >>> .el-checkbox {
  flex: 1;
  margin-left: 10px;
  margin-bottom: 8px;
}
.myview >>> .el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
</style>